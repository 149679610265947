<template>
    <div>
        <el-dialog title="批量修改广告网域" :visible="editUrlShow" width="800px" @close="close">
            <div>
                <el-form :model="adUrl" ref="ruleForm" label-width="140px" class="demo-ruleForm">
                    <el-form-item label="广告网域：" prop="url">
                        <el-input v-model="adUrl.url" placeholder="网域仅包含一级域和二级域，例如：giikin.com"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="sure">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import { addConversionDomain} from "@/api/adManagement";
    export default {
        props:['editUrlShow','list'],
        data() {
            return {
                adUrl:{
                    url:''
                }
            }
        },
        methods: {
            close(){
                this.adUrl.url = '';
                this.$emit('close','editUrlShow')
            },
            sure(){
                if(!this.adUrl.url){
                    this.$message({
                        type:'warning',
                        message:'网域不能为空'
                    })
                }
                
                if(this.list){
                    let ids = this.list.map(v=>v.id);
                    this.$showLoading();
                    addConversionDomain({ad_ids:ids,conversion_domain:this.adUrl.url}).then(res=>{
                        this.$hideLoading()
                        if(res.code == 0){
                            this.$message({
                                type:'success',
                                message:'保存成功'
                            })
                            this.$emit('get-list')
                            this.close()
                        }
                    })
                }else{
                    this.close();
                }
                
            }
        },
    }
</script>
<style lang="scss">
</style>