<template>
    <div>
        <div class="previews">
            <!-- <div class="topPreviw">
                <div class="top-radio">
                    <el-radio v-model="radio" label="1">自动版位</el-radio>
                    <el-radio v-model="radio" label="2">选择版位</el-radio>
                </div>
                <div v-if="radio == 2">
                    <el-select v-model="device_platforms" placeholder="" @change="selectPlacement">
                        <el-option :label="item.desc" :value="item.key"  v-for="(item) in initData.placement.DevicePlatform" :key="item.key"></el-option>
                    </el-select>
                    <div class="tree" v-if="initData.placement">
                        <el-tree
                        :data="platFormList.filter(v=>v.key == treeItem.key)"
                        show-checkbox
                        default-expand-all
                        node-key="key"
                        :props="defaultProps"
                        :default-checked-keys="checkedNodes[treeItem.key+'_positions']"
                        v-for="treeItem in platFormList" :key="treeItem.key">
                        </el-tree>
                    </div>
                </div>
            </div> -->
            <div class="priewIframe">
                <p>广告预览</p>
                <el-select v-model="ad_format" placeholder="请选择" v-if="initData.placement" @change="selectFormat">
                    <el-option-group
                    v-for="group in initData.placement.PublisherPlatform"
                    :key="group.key"
                    :label="group.desc">
                    <el-option
                        v-for="(item,index) in group.positions.filter(v=>v.adFormat)"
                        :key="item.value"
                        :label="item.desc"
                        :value="item.adFormat.key+'&'+index">
                    </el-option>
                    </el-option-group>
                </el-select>
                <div class="iframe" id="iframe" v-html="iframe" ref="previewFrame" v-loading="previewLoading"></div>
            </div>
        </div>
    </div>
</template>
<script>
import {adConstsMap} from '@/api/creatAd.js'
import {adPreview} from '@/api/adManagement.js'
    export default {
        props:['value'],
        data () {
            return {
                radio:'1',
                initData:{},
                previewLoading:false,
                iframe:'',
                ad_format:'MOBILE_FEED_STANDARD&0',
                device_platforms:null,
                activeIndex:[],
                platFormList:[],
                defaultProps: {
                    children: 'positions',
                    label: 'desc',
                },
            }
        },
        methods: {
            // 获取广告创建常量
            async getInitData(){
                await adConstsMap().then(res=>{
                    // console.log(res);
                    this.initData = res.data;
                });
            },
            // 
            selectFormat(v){
                this.previewLoading = true;
                adPreview({ad_id:this.value.id,ad_format:v.split('&')[0]}).then(res=>{
                    // console.log(res);
                    this.iframe = res.data;
                    this.$nextTick(()=>{
                        if(this.$refs.previewFrame.getElementsByTagName('iframe')[0]){
                            this.$refs.previewFrame.getElementsByTagName('iframe')[0].width='100%';
                        }
                    })
                    this.previewLoading = false;
                })
            },
            // 选择设备
            selectPlacement(val){
                // this.$refs.vuetree.setCheckedNodes([]);
                this.checkedNodes = { 
                    facebook_positions:[],
                    instagram_positions:[],
                    audience_network_positions:[],
                    messenger_positions:[]
                };
                let data = JSON.parse(JSON.stringify(this.initData.placement.PublisherPlatform))
                data.map((key,i)=>{
                    key.positions.map((item,m)=>{
                        if(item.devicePlatforms.some(v=>v.key == val)){
                            item.disabled = false;
                            // console.log(this.checkedNodes[key.key+'_positions']);
                            this.checkedNodes[key.key+'_positions'].push(item.key)
                        }else{
                            item.disabled = true;
                        }
                        // console.log( this.checkedNodes);
                        this.$set(key.positions,m,item)
                    })
                    this.$set(data,i,key)
                    this.platFormList = data
                    // this.$refs.vuetree.setCheckedNodes(this.platFormList);
                })
                // this.$forceUpdate();
            },
        },
        watch:{
            radio:{
                handler(){
                    if(this.radio == 2){
                        this.platFormList = JSON.parse(JSON.stringify(this.initData.placement.PublisherPlatform));
                        this.device_platforms = 'mobile';
                        this.selectPlacement('mobile')
                    }
                }
            },
            value:{
                handler(){
                    this.selectFormat(this.ad_format)
                },
                deep:true,
            }
        },
        mounted() {
            this.selectFormat('MOBILE_FEED_STANDARD')
            this.getInitData();
        },
    }
</script>
<style lang="scss" scope>
    .previews{
            // flex: 300px 0 0;
            background: #fff;
            // background-color: #efefef;
            margin-left: 20px;
            .topPreviw{
                padding: 10px;
            }
            // padding: 10px;
            .top-radio{
                padding: 10px;
            }
            .el-select{
                width: 100%;
                line-height: 30px;
            }
            .tree{
                margin-top: 10px;
                max-height: 200px;
                overflow-y: auto;
                &::-webkit-scrollbar {
                    width: 6px;    
                // height: 6px;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                    background: rgba(0,0,0,0.2);
                }
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                    border-radius: 0;
                    background: rgba(0,0,0,0.1);
                }
            }
            .priewIframe{
                // margin-top: 10px;
                background: #fff;
                // border-top: 5px solid #f5f5f5;
                padding: 10px;
                // width: 370px;
                p{
                    padding: 10px;
                    font-size: 14px;
                }
                .iframe{
                    width: 100%;
                    margin-top: 10px;
                    min-height: 300px;
                    // height: 300px;
                }
            }
        }
</style>